import "./App.css";
import React from "react";
import Start from "./components/Start";
import Navigation from "./components/Navigation";
import Missed from "./components/Missed";
import SocialMedia from "./components/SocialMedia";
import Partners from "./components/Partners";
import Sponsors from "./components/Sponsors";
import Contact from "./components/Contact";
import Bots from "./components/Bots";
import { Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <box className="bg">
        <style>
          {
            'body {background-image: url("../img/background.jpeg");background-repeat: no-repeat;background-size: cover;background-attachment: fixed;}'
          }
        </style>
        <Navigation />
        <SocialMedia />
        <Route exact path="/" component={Start} />
        <Route exact path="/Missed" component={Missed} />
        <Route exact path="/Sponsors" component={Sponsors} />
        <Route exact path="/Partners" component={Partners} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/Bots" component={Bots} />
      </box>
    </div>
  );
}

export default App;
