function Partners() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a href="https://steamwork.be">
        <img
          src="../img/steamworkvzwwieltje.png"
          alt="Steamwork logo"
          style={{
            width: "200px",
            height: "auto",
            margin: "20px",
            display: "block",
          }}
        />
      </a>
    </div>
  );
}

export default Partners;
