function Bots() {
    return (
<div>
      <box className="DefaultBox">
        <p class="DefaultText">
        <h1>Battle Bots 2025</h1>
        <br></br>
        Op donderdag 1 mei 2025 vindt Battle Bots plaats in Hoeilaart. Gedurende de dag ga je aan de slag met verschillende uitdagingen, die uiteindelijk uitmonden in een spectaculaire finale waarin de Battle Bots het tegen elkaar opnemen!
        <br></br><br></br>
        <h4>Wie kan meedoen?</h4>
        Battle Bots is toegankelijk voor jongeren vanaf 7 jaar, verdeeld in drie leeftijdscategorieën:
        <br></br>
        <li>7-9 jaar</li>
        <li>10-12 jaar</li>
        <li>12+</li>
          Je schrijft je in per team van maximaal 2 personen. De inschrijvingskost bedraagt €10 per persoon. 
        <br></br>
        <br></br>
        <h4>Tracks en uitdagingen</h4>
        Je kunt deelnemen aan één van de twee tracks:
        <br></br>
        <br></br>
        <strong>Track 1:</strong> Ontwerp en bouw je eigen robot thuis met je eigen LEGO-materiaal. Het volledige materiaal moet in één plooibak passen (meer informatie volgt nog).
        <br></br>
        <br></br>
        <strong>Track 2:</strong> Bouw je robot op de dag zelf met een standaard robotkit die je ter plaatse ontvangt. Tijdens het evenement krijg je tijd om je robot samen te stellen.
        <br></br>
        <br></br>
        Tussen de verschillende uitdagingen door krijg je steeds de kans om je Battle Bot te verbeteren en aan te passen. 
        <br></br>
        <br></br>
        <h4>Ben jij klaar om de strijd aan te gaan?</h4>
        Kom mee bouwen, experimenteren en strijden met jouw ultieme Battle Bot! 🤖
        Registreer je e-mail adres op het "Contacteer ons" formulier en we houden je op de hoogte!
        </p>
      </box>
    </div>
    );
  }

  export default Bots;