function Sponsors() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <a href="https://sensuniek.solutio.app/">
            <img
              src="../img/sensuniek.png"
              alt="SensUniek logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                margin: "20px",
                display: "block",
              }}
            />
          </a>
          <a href="https://pathtomastery.be">
            <img
              src="../img/pathtomastery.jpg"
              alt="Path To Mastery logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                margin: "20px",
                display: "block",
              }}
            />
          </a>
          <a href="http://annabrewards.com">
            <img
              src="../img/ABRBee_small.png"
              alt="ARB logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                margin: "20px",
                display: "block",
              }}
            />
          </a>
          <a href="https://www.facebook.com/denonsteven/">
            <img
              src="../img/denonstevenlogo.jpeg"
              alt="StevenDenon logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                margin: "20px",
                display: "block",
              }}
            />
          </a>
        </div>
      </div>
    );
  }
  
  export default Sponsors;
  