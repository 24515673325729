function Start() {
  return (
    <div>
      <box className="DefaultBox">
        <p class="DefaultText">
          We-IT een diverse en open vereniging zonder winstoogmerk voor
          15-147-jarigen die op een leuke manier kennis en vaardigheden binnen
          de IT en wetenschap willen delen en/of leren.
          <br></br>
          <br></br>
          Mensen uit alle bevolkingsgroepen inspireren en nieuwe inzichten
          bezorgen is ons doel!
        </p>
      </box>
    </div>
  );
}

export default Start;
