function SocialMedia() {
  return (
    <div class="icon-bar">
      <a href="https://www.facebook.com/WeITdruivenstreek/" class="facebook">
        <i class="fa fa-facebook"></i>
      </a>

      <a
        href="https://instagram.com/we_it_druivenstreek?utm_medium=copy_link"
        class="instagram"
      >
        <i class="fa fa-instagram"></i>
      </a>

      <a
        href="https://we-it.be/Contact"
        class="envelope"
      >
        <i class="fa fa-envelope"></i>
      </a>

      <a
        href="https://www.youtube.com/@We-IT_Druivenstreek"
        class="youtube"
      >
        <i class="fa fa-youtube"></i>
      </a>

    </div>
  );
}
export default SocialMedia;
