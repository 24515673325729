import { Nav, Navbar } from "react-bootstrap";

function Navigation() {
  return (
    <Navbar classname="navbar" expand="lg" sticky="top">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <img
            class="navbarLogo"
            src="../img/logo.png"
            alt="Home Button"
            width="65px"
          />
        </Nav>
        <Nav className="mr-auto">
          <Nav.Link id="navbarButton" href="/">
            Over Ons
          </Nav.Link>
          <Nav.Link id="navbarButton" href="/Missed">
            Wat heb je gemist?
          </Nav.Link>
          <Nav.Link id="navbarButton" href="/Sponsors">
            Onze sponsors
          </Nav.Link>
          <Nav.Link id="navbarButton" href="/Partners">
            Onze partners
          </Nav.Link>
          <Nav.Link id="navbarButton" href="/Contact">
            Contacteer ons
          </Nav.Link>
          <Nav.Link id="navbarButton" href="/Bots">
            Battle Bots
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Navigation;
